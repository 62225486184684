import React from 'react';
import { observer } from 'mobx-react';
import { FlexColumn } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import AlertingTable from 'app/views/alerting/components/AlertingTable';
import AlertCollection from 'app/stores/alerting/AlertCollection';

@storeLoader('$alerting.policyCollection')
@observer
class JourneyAlertResponse extends React.Component {
  state = {
    collection: new AlertCollection()
  };

  componentDidMount() {
    const { collection } = this.state;
    const { query } = this.props;
    // Override because we don't need server filters and it breaks inline filters.
    collection.serverSortableFields = [];
    // Override because we don't want to clearFilters on result collapse/expand.
    collection.clearFilters = () => {};
    // flatten these because the collection re-nests under filter before fetching.
    const { filter, pagination } = query.get('query');
    collection.setServerFilter({ ...filter, pagination });
  }

  legacyStates(legacyStates = []) {
    const stateObj = {};
    legacyStates.forEach((state) => {
      stateObj[state] = state;
    });
    const { ackReq, alarm, clear } = stateObj;
    const ACK_STATES = {
      ackReq: 'ALARM_ACKNOWLEDGEMENT_REQUIRED',
      alarm: 'ALARM_STATE_ACTIVE',
      clear: 'ALARM_STATE_CLEAR'
    };
    const states = [clear, alarm].map((state) => ACK_STATES[state]).filter((test) => test);
    let ackStates = [ackReq].map((state) => ACK_STATES[state]).filter((test) => test);
    if (ackStates?.length > 0) {
      ackStates = ackStates.concat(['ALARM_ACKNOWLEDGEMENT_DONE', 'ALARM_ACKNOWLEDGEMENT_NOT_ACKED']);
    }
    return { states, ackStates };
  }

  legacySeverities(legacySeverities = []) {
    const SEVERITY_MAP = {
      criticial: 'SEVERITY_CRITICAL',
      major2: 'SEVERITY_SEVERE',
      major: 'SEVERITY_MAJOR',
      minor2: 'SEVERITY_WARNING',
      minor: 'SEVERITY_MINOR'
    };
    return legacySeverities.map((sev) => SEVERITY_MAP?.[sev]).filter((sev) => sev);
  }

  legacyToAlertManager() {
    const { query, $alerting } = this.props;
    const {
      states,
      lookback,
      severities,
      policies,
      limit = 100,
      keyPartsSearch,
      startDate,
      endDate,
      type
    } = query.get('query');
    const filters = {};
    filters.applications = type || ['ddos'];
    filters.severities = this.legacySeverities(severities);
    if (lookback) {
      filters.lookback = lookback;
    }
    if (startDate && endDate) {
      filters.active = {
        start: startDate,
        end: endDate
      };
    }
    filters.pagination = {
      limit,
      offset: 0
    };
    if (policies) {
      filters.ruleIds = policies
        .map((policyId) => $alerting.policyCollection.get(policyId)?.ruleId)
        .filter((rule) => rule);
    }
    filters.keyPartsSearch = keyPartsSearch;
    return { ...filters, ...this.legacyStates(states) };
  }

  render() {
    const { collection } = this.state;

    return (
      <FlexColumn flex={1} position="relative" overflow="hidden">
        <AlertingTable collection={collection} miniKebab useVirtualizedTable={false} />
      </FlexColumn>
    );
  }
}

export default JourneyAlertResponse;
